<template>
  <v-main>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card class="elevation-12">
            <v-toolbar dark color="primary">
              <v-toolbar-title>{{ $t('login.title') }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <!-- <v-tooltip bottom>
                <v-btn icon large :href="source" target="_blank" slot="activator">
                  <v-icon large>code</v-icon>
                </v-btn>
                <span>Source</span>
              </v-tooltip> -->
              <v-progress-circular
                indeterminate
                v-if="loading"
              ></v-progress-circular>
            </v-toolbar>
            <v-card-text>
              <v-alert type="error" v-if="isShowMsg">
                {{ messageText }}
              </v-alert>
              <div class="title mb-3">เข้าสู่ระบบด้วยบัญชี Google</div>
              <div id="google_btn"></div>
            </v-card-text>
          </v-card>
          <!-- <v-row class="justify-end">
            <v-col cols="3">
              <v-select
                :items="langItems"
                v-model="lang"
                item-text="text"
                item-value="lang"
                dense
                outlined
                class="mt-4"
                @change="changeLang()"
              ></v-select>
            </v-col>
            
          </v-row> -->
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import { useJwt } from '@vueuse/integrations/useJwt'
import Api from "@/api/Api";

export default {
  data: () => ({
    username: "",
    password: "",
    messageText: "",
    isShowMsg: false,
    loading: false,
    langItems: [
      { lang: "th", text: "TH"},
      { lang: "en", text: "EN"}
    ],
    lang: 'th'
  }),
  mounted() {
    // function handleCredentialResponse(response) {
    //   // console.log('handleCredentialResponse', response)
    //   this.loginWithGoogle(response)
    // }
    // console.log(window.google)
    window.google.accounts.id.initialize({
      client_id: '1079498923151-i92q9hve90ecgpblddineh771fqn7hbo.apps.googleusercontent.com',
      callback: this.loginWithGoogle
    });
    const parent = document.getElementById('google_btn');
    window.google.accounts.id.renderButton(parent, {theme: "filled_blue", text: "signin_with"});
    // // window.google.accounts.id.prompt();
  },
  methods: {
    async loginWithGoogle(response){
      // console.log('google data', response)
      const payload = useJwt(response.credential)
      // console.log('google data', payload.payload.value.email)
      // this.loading = true

      const req = {
        email: payload.payload.value.email,
      }

      let res = (await Api().post("user/login", req)).data;

      if (res.status == 'success') {
        let userData = res.data
        this.$store.commit('setUser', userData)
        this.$store.commit('setToken', userData.user_token)

        this.$router.push('/request/list')
      }else{
        this.isShowMsg = true
        this.messageText = "Username นี้ไม่ถูกต้อง"
        this.loading = false
      }
    },
    changeLang(){
      // console.log(this.$i18n.locale)
      this.$i18n.locale = this.lang
    }
  }
}

</script>
